import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { reportsFeeService } from '../../shared/services/reportsFee.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { SelectList } from '../../shared/model/SelectList.model';

@Component({
  selector: 'app-overall-dump',
  templateUrl: './overall-dump.component.html',
  styleUrls: ['./overall-dump.component.css'],
  animations: [
    SlideInFromLeft()
  ]
})

export class OverallDumpComponent implements OnInit {

  study_lvl : Array<SelectList>;
  @ViewChild('f') formRef: NgForm;
  loader: boolean;
  details =
  `
  <h5 class='text-left' style="text-transform: case;">SCREEN DETAILS</h5>
  <hr>
  <h6 class='text-left'>  You can download  dump data that contains all the students information according to study level and year wise  </h6>
  <h6 class="text-right">آپ ڈمپ ڈیٹا ڈاؤن لوڈ کر سکتے ہیں جس میں تمام طلباء کی معلومات ان کے تعلیمی سطح اور سال کے لحاظ سے موجود ہوتی ہیں۔
</h6>
  `  ;

  constructor(
    private reportsFeeService: reportsFeeService,
    private toastr: ToastrService
  ) { 
  }

  ngOnInit(): void {
    this.getStudyLevel();
    this.loader = false;
  }

  getStudyLevel() {
    this.study_lvl=[]
    this.reportsFeeService.getStudyLVL().subscribe((res: any) => {
      res[0]?.forEach(entry => {
        this.study_lvl.push({ id: entry.lvlid, nm: entry.des });
      });
    })
  }

  getData() { 
    this.loader = true;
    let header=["Form Number","Student Name", "Father Name", "Roll Number", "Registration Number",
    "Gender","Religion", "Shift", "Department", "session", "Subject combination", "program", "CNIC",  
    "Address", "Student Contact No 1", "Guardian Contact"," Date of Birth", "district","Tehsil",
    "Province","E-mail", "Admission Base", "Spring/Fall","Inter Total","Inter Obtain" ]

    this.reportsFeeService.overallDumpReport(this.formRef.value.lvl_id,this.formRef.value.year).subscribe((res: any) => {
    new AngularCsv(res[0], "Overall Dump Report", { headers: (header) });
    this.loader = false;
    })
  }
}
