import { DegreeprintService } from './../shared/services/degreeprint.service';
import { MajorService } from './../shared/services/Major.service';
import { SelectList } from 'src/app/main/shared/model/SelectList.model';
import { ClassService } from './../shared/services/Class.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SlideInFromLeft } from 'src/app/transitions';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { PDFDocument } from "pdf-lib";
import { LMSharedDataService } from 'src/app/shared/LMSSharedService';
import QRCode from 'qrcode';

export interface IsDetail {
  ID: number,
  YEAR: number,
  C_CODE: number,
  SE_ID: number,
  RN: number,
  D_O_NOTIF: Date,
  P_YEAR: number,
  DEG_REC_YEAR: number,
  DEG_REC_DATE: Date,
  DEG_NO: number,
  DT: Date,
  MAJ_ID: number,
  DEG_CTGRY: string,
  TRNS_NO: number
}

export interface RGBAData {
  data: Uint8ClampedArray;
  width: number;
  height: number;
}

@Component({
  selector: 'app-degree-report-print',
  templateUrl: './degree-report-print.component.html',
  styleUrls: ['./degree-report-print.component.css'],
  animations: [SlideInFromLeft()],
})

export class DegreeReportPrintComponent implements OnInit {
  class: Array<SelectList>
  majors: Array<SelectList>
  category: string = "";
  qrCodeValue: string;
  updating: boolean = true;
  degreeinfo: Array<{ year: number, F_NM: string, NM: string, ROLNO: string, maj: string, lvl: number, CGPA: number, REG_NO: string, class: string, p_year: string, gender: string, trns_no: number }>
  deg_data: Array<{ year: number, F_NM: string, NM: string, ROLNO: string, maj: string, CGPA: number, REG_NO: string, TRNS_NO: number }>;
  @ViewChild("f") formRef: NgForm;
  constructor(
    private degree: DegreeprintService,
    private toaster: ToastrService,
    private lmsSharedService: LMSharedDataService) {
    this.class = [];
    this.majors = [];
    this.degreeinfo = [];
    this.deg_data = new Array<{ year: number, F_NM: string, NM: string, ROLNO: string, maj: string, CGPA: number, REG_NO: string, TRNS_NO: number }>();
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.class = classes;
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  updateMajors() {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClassChange() {
    this.updateMajors();
  }

  click() {
    if (this.formRef.value.category === "Urgent") {
      this.category = "U";
    }
    else if (this.formRef.value.category === "Regular") {
      this.category = "R";
    }
    else if (this.formRef.value.category === "Duplicate") {
      this.category = "D"
    }
    this.toaster.info('Please Wait Data is Loading');
    this.degree.getDegreeInfo(this.formRef.value.c_code, this.formRef.value.maj_id, this.formRef.value.notifDate,
      this.formRef.value.notifDate1, this.category, this.formRef.value.t_no).subscribe((data: any) => {
        const { msg, warning } = data;
        if (warning) {
          this.toaster.warning("Failed to get !");
        }
        this.degreeinfo = [];
        this.deg_data = [];
        if (data != 0) {
          for (let i = 0; i < data.length; i++) {
            this.degreeinfo.push({ year: data[i].year, F_NM: data[i].F_NM, NM: data[i].NM, ROLNO: data[i].ROLNO, maj: data[i].maj, lvl: data[i].lvl, CGPA: data[i].CGPA.toFixed(2), REG_NO: data[i].reg, class: data[i].class, p_year: data[i].Year, gender: data[i].gender, trns_no: data[i].trns_no });
            //this.deg_data.push({ year: data[i].year, F_NM: data[i].F_NM, NM: data[i].NM, ROLNO: data[i].ROLNO, maj: data[i].maj, CGPA: data[i].CGPA, REG_NO: data[i].reg, TRNS_NO: data[i].TRNS_NO });

          }
          this.toaster.success("Data Found Succesfully")
        }
        else {
          this.toaster.warning("Data not found!!!")
        };
      })
  }

  // Updated generate function with async/await for better QR code handling
  async generate(el) {
    this.qrCodeValue = `https://gcu.edu.pk/degreeVerificaton.php?transNo=${el.trns_no}`;

    // Function to convert string to PascalCase
    const toPascalCase = str =>
      str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join(' ');

    var doc = new jsPDF('l', 'mm', 'legal');

    // Add text elements to the PDF
    doc.setFontSize(15);
    doc.setTextColor(2, 24, 74);
    doc.setFont(undefined, 'bold');
    doc.text(el.REG_NO || 'NULL', 298, 30, { align: 'center' });
    doc.setFontSize(20);
    doc.text(toPascalCase(el.NM) + ' ' + el.gender + ' ' + toPascalCase(el.F_NM), 174, 100, { align: 'center' });
    doc.text(el.ROLNO, 174, 110, { align: 'center' });
    doc.setFontSize(18)
    doc.text(el.maj, 175, 150, { align: 'center' });
    doc.setFontSize(20);
    doc.text('in the year ' + el.p_year + ' securing a CGPA of ' + el.CGPA + '/4.00', 175, 160, { align: 'center' });
    
    if ((el.year >= 2020 && el.lvl == 2) || (el.year >=2022 && el.lvl >= 4 )){
      try {
        const url = await QRCode.toDataURL(this.qrCodeValue);
        var pageSize = doc.internal.pageSize;

        const qrCodeSize = 30; // Size of the QR code in mm
        doc.addImage(url, 'PDF', 28, 34, qrCodeSize, qrCodeSize);// changed by mehtab against qr code positioning.
        doc.setFontSize(9);
      } catch (err) {
        console.log("Error generating QRCode", err);
      }
    }

    const page = doc.output('arraybuffer');
    return page;
  }

  async downloadAll() {
    const buffers = [];

    // Generate PDFs for each degree info and add to buffers
    for (const el of this.degreeinfo) {
      const buf = await this.generate(el); // Ensure generate is awaited
      if (buf != null) buffers.push(buf);
    }

    if (buffers.length <= 0) {
      this.toaster.warning("No Any Data To Export");
      this.updating = false;
      return;
    } else {
      this.toaster.success(`Data of ${buffers.length} Student/s Exported.`);
      setTimeout(() => {
        this.mergePdfs(buffers);
        this.updating = false;
      }, 500);
    }
  }

  async mergePdfs(pages: ArrayBuffer[]) {
    const mergedPdf = await PDFDocument.create();
    mergedPdf.setTitle('Degree', { showInWindowTitleBar: true });
    const actions = pages.map(async (pdfBuffer) => {
      const pdf = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page);
      });
    });
    await Promise.all(actions);
    mergedPdf.setTitle('Degree');
    const pdfBytes = await mergedPdf.save();

    let file = new Blob([pdfBytes], { type: 'application/pdf' });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  }
}