import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { SlideInFromLeft } from "src/app/transitions";
import { ProgressServices } from "../progress-report/progress-services";
import { SelectList, SelectList2 } from "../shared/model/SelectList.model";
import { MajorService } from "../shared/services/Major.service";
import { SessionService } from "../shared/services/Sessions.service";
import { RGBAData, jsPDF } from "jspdf";
import autoTable, { UserOptions } from "jspdf-autotable";
import { AttendanceRelaxationService } from "../attendance-relaxation/attendance-relaxation.service";
import { DatePipe } from "@angular/common";
import { arialblack, font } from "src/font";
import { LMSharedDataService } from "src/app/shared/LMSSharedService";
import QRCode from 'qrcode';

interface jsPDFWithPlugin extends jsPDF {
  autoTable: (options: UserOptions) => jsPDF;
}

export interface subjectInformation {
  Father_name: string;
  GP_PER: number;
  Name: string;
  Roll_number: string;
  SUB_CODE: string;
  c_class: string;
  grd: string;
  reg_no: null;
  req_cr_hr: 3;
  session: string;
  subject_name: string;
  t_no: number;
  atmp_no: string;
  orig_tno: number;
}
export interface studentNamesInformation {
  NM: string;
  ROLNO: string;
  F_NM: string;
  REG_NO: string;
  DES: string;
  MAJTITLE: string;
  SE_ID: string;
  rolnum: string;
  dur: number;
  lvl: number;
}
export interface studentCgpaInformation {
  ROLNO: string,
  cr_h: number,
  // earn: number;
  gp: number,
  sgpa: number,
  t_no: number,
  cgpa: number,
  mrks: number,
  total: number
}
// export interface stdRollDetail {
//   RN: number;
//   ROLNO: string;
//   NM: string;
// }
@Component({
  selector: "app-transcript",
  templateUrl: "./transcript.component.html",
  styleUrls: ["./transcript.component.css"],
  animations: [SlideInFromLeft()],
})
export class TranscriptComponent implements OnInit {
  classes: Array<SelectList>;
  qrCodeValue: string;
  //qrCodeValue = `https://gcu.edu.pk/?ROLNO=${this.ROLNO}`
  // repeat: Map<string, number>;
  // cr_hrs: Map<number, number>;
  majors: Array<SelectList>;
  rolnumbers: Array<SelectList>;
  session: Array<SelectList2>;
  @ViewChild("f") formRef: NgForm;
  transcriptMap: Map<number, subjectInformation[]>;
  stdNamesInfo: studentNamesInformation;
  stdCgpaInfo: Array<studentCgpaInformation>;
  se_select: any;
  rol: any;
  selected: any;
  maj: any;
  sem = 8;
  // num: any;
  // public sbInfo: any;
  // total_cr_h:number;
  total_grade_point;
  total_cgpa;
  mrks: number = 0;
  total: number = 0;
  percent: number = 0;
  stdCrh: Map<string, number[]>;
  today: any = new Date();
  stdRollNo = "";
  hideBtn = false;
  updating: boolean = false;
  st_nm = "";
  st_rn = "";

  constructor(
    private toaster: ToastrService,
    private majService: MajorService,
    private getServe: AttendanceRelaxationService,
    private sessionService: SessionService,
    private transServices: ProgressServices,
    private datepipe: DatePipe,
    private lmsSharedService: LMSharedDataService
  ) {
    this.classes = [];
    this.majors = [];
    this.session = [];
    // this.repeat = new Map<string, number>();
    // this.cr_hrs = new Map<number, number>();
    this.transcriptMap = new Map<number, subjectInformation[]>();
    this.stdCgpaInfo = new Array<studentCgpaInformation>();
    // this.total_cr_h = 0.0;
    this.total_cgpa = 0.0;
    this.total_grade_point = 0.0;
    this.stdRollNo = "";
    this.stdCrh = new Map<string, number[]>();
    this.today = moment(this.today).format("DD-MMM-YYYY");
  }

  ngOnInit(): void {
    this.updateClasses();
  }

  updateClasses() {
    this.lmsSharedService.getLMSClasses()
      .then(classes => {
        this.classes = classes;
        this.selected = this.classes[0]?.id;
        this.updateMajors(this.selected);
      })
      .catch(error => {
        console.error("Error getting fee classes", error);
      });
  }

  // updateClasses() {
  //   this.getServe
  //     .getClasses()
  //     .subscribe((res: { C_CODE: number; C_NM: string }[]) => {
  //       if (res?.length == 0 || res == null) {
  //         this.toaster.error("No Any Classes");
  //         return;
  //       } else {
  //         res?.forEach((entry) => {
  //           this.classes.push({ id: entry.C_CODE, nm: entry.C_NM });
  //         });
  //         this.selected = res[0]?.C_CODE;
  //         this.updateMajors(this.selected);
  //       }
  //     });
  // }

  updateMajors(c_code: any) {
    this.majors.length = 0;
    this.lmsSharedService.filterMajorByCode(this.formRef.value.c_code)
      .then(major => {
        this.majors = major
        if (this.formRef) {
          const initialValue = this.majors[0]?.id;
          this.formRef?.controls['maj_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting majors", error)
      })
  }

  OnClassChange(c_code) {
    this.updateMajors(c_code?.value);
    this.updateSession(c_code?.value)
  }

  updateSession(c_code) {
    this.lmsSharedService.filterSessionByCode(c_code)
      .then(session => {
        this.session = session
        if (this.formRef) {
          const initialValue = this.session[0]?.id;
          this.formRef?.controls['se_id'].setValue(initialValue);
        }
      })
      .catch(error => {
        console.log("Error getting sessions", error)
      })
  }

  OnSessionIDChange() {
    this.loadRollno();
  }

  onChangeMajor() {
    this.loadRollno();
  }

  loadRollno() {
    this.rolnumbers = [];
    if (
      !this.formRef.value?.se_st_year ||
      !this.se_select ||
      !this.formRef.value?.t_no
    ) {
      return;
    }
    this.getServe
      .getStudents(
        this.formRef.value?.se_st_year,
        "S",
        this.se_select,
        this.formRef.value?.t_no,
        "-75"
      )
      .subscribe((res: { RN: number; ROLNO: string }[]) => {
        res?.forEach((element) => {
          this.rolnumbers.push({ id: element.RN, nm: element.ROLNO });
        });
        this.rol = res[0]?.RN;
        this.OnRollNoChange();
      });
  }

  OnRollNoChange() {
    if (
      !this.formRef.value?.se_st_year ||
      !this.formRef.value?.c_code ||
      !this.se_select ||
      !this.formRef.value?.t_no ||
      !this.rol
    ) {
      return;
    }
    this.getServe
      .getStName(
        this.formRef.value?.se_st_year,
        this.formRef.value?.c_code,
        this.se_select,
        this.formRef.value?.t_no,
        this.rol
      )
      .subscribe((res: { st_name: string }) => {
        this.st_nm = res[0]?.st_name;
      });
  }
  onClear() {
    this.st_nm = "";
    this.formRef.reset();
    this.rolnumbers = [];
    this.session = [];
    this.majors = [];
  }

  OnSubmit(flag: boolean) {
    if (this.formRef.value.passYear != "" && this.formRef.value.notifDate != "" && this.formRef.value.transcript != "") {
      this.insertDegInfo();
    }

    this.mrks = 0; this.total = 0; this.percent = 0;
    // this.total_cr_h=0;
    this.total_grade_point = 0;
    this.transcriptMap.clear();
    this.stdCgpaInfo = [];
    this.stdCrh.clear();
    let se_st_year = this.formRef.value.se_st_year;
    // this.repeat.clear();
    try {
      this.transServices
        .getExamRslt(
          this.formRef.value.c_code,
          this.formRef.value.se_id,
          this.formRef.value.rn,
          this.formRef.value.rn,
          this.formRef.value.se_st_year,
          this.formRef.value.maj_id
        )
        .subscribe(
          (
            res: [
              studentNamesInformation[],
              subjectInformation[],
              studentCgpaInformation[],
              { Roll_number: string, orig_tno: number, req_cr_hr: number }[],
              { obt_marks: number, ov_percentage: number, t_marks1: number }[]

            ]
          ) => {
            this.stdNamesInfo = res[0][0];
            res[1].forEach((entry) => {
              if (!(this.transcriptMap.has(entry.orig_tno)))
                this.transcriptMap.set(entry.orig_tno, new Array<subjectInformation>());
              this.transcriptMap.get(entry.orig_tno).push(entry);
            });

            res[2]?.forEach((entry) => {
              this.stdCgpaInfo[entry.t_no - 1] = entry;
            });
            res[3]?.forEach((entry) => {
              if (!(this.stdCrh.has(entry.Roll_number)))
                this.stdCrh.set(entry.Roll_number, new Array<number>());
              this.stdCrh.get(entry.Roll_number)[entry.orig_tno - 1] = entry.req_cr_hr;
            });

            // for (let i in this.stdCgpaInfo) {
            //   this.total_grade_point += this.stdCgpaInfo[i].gp;
            //   this.total_cgpa += this.stdCgpaInfo[i].sgpa;
            //   this.mrks += this.stdCgpaInfo[i].mrks;
            //   this.total += this.stdCgpaInfo[i].total;
            // }

            res[4]?.forEach((entry) => {

              this.mrks = entry.obt_marks;
              this.total = entry.t_marks1;
              this.percent = entry.ov_percentage;

            });
            this.mrks;
            this.total;
            this.stdCgpaInfo?.forEach(element => {
              this.total_grade_point += element.gp;
              this.total_cgpa += element.sgpa;


            });

            let c_Codeee = this.formRef?.value?.c_code;
            console.log(flag)
            if (flag) {
              console.log(se_st_year)
              console.log(c_Codeee)
              if ((c_Codeee == 7 || c_Codeee == 56 || c_Codeee == 50 || c_Codeee == 9 || c_Codeee == 57 || c_Codeee == 54 || c_Codeee == 18 || c_Codeee == 9 || c_Codeee == 10 || c_Codeee == 12 /*|| c_Codeee == 16*/ || c_Codeee == 17 || c_Codeee == 53 || c_Codeee == 77) || ((c_Codeee == 14 || c_Codeee == 15) && se_st_year > 2021)) {
                this.OnMphilCreatePdf();
                return;
              }
              else if ((c_Codeee == 19 || c_Codeee == 16 || c_Codeee == 13) || ((c_Codeee == 14 || c_Codeee == 15) && se_st_year <= 2021)) {
                console.log(1)
                this.OnMBACreatePdf();
                return;
              }
              this.OnCreatePDF();
            }
            else
              this.OnletterCreatePDF();

            // } catch (err) {
            //   this.toaster.error("Unknown Problem");
            // }
          }
        );
    } catch (err) {
      this.toaster.error("Internet Problem");
    }
  }
  public sgp: any;
  // public number1;
  insertDegInfo() {
    this.transServices.insertUpdateDegInfo(this.formRef.value).subscribe((res: any) => {
      if (res[0].msg) {
        this.toaster.success(res[0].msg)
      }
    });
  }


  OnCreatePDF() {
    console.log('Pdf')
    let mrks = this.mrks;
    let total = this.total;
    let sgpaArr = this.stdCgpaInfo;
    let dt1 = this.datepipe.transform(this.formRef.value.notifDate, 'dd-MM-yyyy');
    let pasYear = this.formRef.value.passYear;
    let m = 0;
    let k = -3;
    let endRes = 0;
    const roman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII'];
    // this.number1 = 'not empty';
    let data = this.stdNamesInfo;
    let crhArr = this.stdCrh.get(this.stdNamesInfo.rolnum);
    if (
      data == null ||
      data == undefined
    ) {
      this.toaster.warning("No Any Data To Export");
      return;
    } else {
      this.toaster.info("Downloading");
      let extra = 0;
      let body75 = [];
      const header = [];
      let se = data.SE_ID;
      let stNm = data.NM;
      let f_nm = data.F_NM;
      let rlNo = data.ROLNO;
      let transcriptNo = this.formRef.value.transcript;
      this.qrCodeValue = `https://gcu.edu.pk/degreeVerificaton.php?transNo=${transcriptNo}`
      let rgNo = data.REG_NO;
      let majTitle = data.MAJTITLE;
      let totalGradePoint = this.total_grade_point;
      let duration = data.dur * 2;
      let durYear = data.dur;
      let dt = this.datepipe.transform(this.today, 'dd-MM-yyyy');
      let cgpaLen = this.stdCgpaInfo.length; //transcript and stdCgpaInfo size & length has same.
      // const doc = new jsPDF("p", "mm", "legal");
      let c_Codee = this.formRef?.value?.c_code;
      const doc = new jsPDF("p", "mm", [222, 356]);
      doc.addFileToVFS('FontsFree-Net-ariblk-normal.ttf', arialblack);
      doc.addFont('FontsFree-Net-ariblk-normal.ttf', 'Arial', 'black');
      doc.addFileToVFS('Arialn-normal.ttf', font);
      doc.addFont('Arialn-normal.ttf', 'Arial', 'normal');
      doc.setFont("times", "normal");
      doc.setFontSize(11);
      doc.text("" + rlNo, 157 + m, 31 + k);   //31
      doc.text("" + rgNo, 82 + m, 31 + k);   //31
      doc.setFont("times", "bold");
      doc.setFontSize(14);
      doc.text("" + majTitle, 112 + m, 48 + k, { align: "center" });
      doc.setFont("times", "normal");
      doc.setFontSize(10);
      doc.text("Student Name: ", 7 + m, 63.5 + k);
      doc.text("" + stNm, 40 + m, 63.5 + k);
      doc.text("Father Name: ", 115 + m, 63.5 + k);
      doc.text("" + f_nm, 145 + m, 63.5 + k);
      doc.text("Duration:", 7 + m, 68.6 + k);
      doc.text(`${durYear}-Years`, 40 + m, 68.6 + k);
      doc.text("Session: ", 115 + m, 68.6 + k);
      doc.text("" + se, 145 + m, 68.6 + k);
      doc.text("Mode of Study: ", 7 + m, 73.2 + k);
      doc.text("Regular", 40 + m, 73.2 + k);
      doc.text("Name Of Campus: ", 115 + m, 73.2 + k);
      doc.text("GC University, Lahore", 145 + m, 73.2 + k);
      doc.text("He/She has passed " + majTitle + " in the year " + pasYear + ".", 7 + m, 80.5 + k);
      let cursor1 = 86 + k;
      let cursor2 = 86 + k;
      header.push(["Course Code", "Title", "Cr H", "Grd", "GP"]);

      for (let j = 1; j <= cgpaLen; j++) {
        if (j > 2) extra = 7;
        let sub = this.transcriptMap.get(j);
        for (let i = 0; i < sub?.length; i++) {
          let arr = sub;
          body75.push([
            arr[i].SUB_CODE.toUpperCase(),
            arr[i].subject_name,
            arr[i].req_cr_hr,
            arr[i].grd,
            arr[i].GP_PER.toFixed(1)
          ]);
        }
        doc.setFontSize(9);
        let totalCrHH = 0;
        let gp11 = 0;
        let sgpa11 = 0;
        if ((c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 14)) {
          totalCrHH = crhArr[j - 1];
          gp11 = this.stdCgpaInfo[j - 1]?.gp || 0;
          sgpa11 = this.stdCgpaInfo[j - 1]?.sgpa || 0;
        }

        if (j % 2 != 0) {
          autoTable(doc, {
            styles: {
              overflow: 'linebreak',
              fontSize: 7.5,
              cellPadding: 0,
            },
            startY: cursor1 + extra,
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 52 },
              2: { cellWidth: 10 },
              3: { cellWidth: 10 },
              4: { cellWidth: 10 },
            },
            margin: { left: 7 },
            theme: "plain",
            body: body75,
            head: header,
            tableWidth: 107,
            didDrawPage: function (d) {
              doc.setFontSize(8).setFont("Arial", "black");
              if ((c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 14) && (j == 3)) {
                duration = 3;
                doc.text("Semester- " + roman[j - 1] + " & " + roman[j], 7, cursor1 + extra - 1);
                cursor1 = d.cursor.y;
                cursor1 = Math.max(cursor1, cursor2);
                doc.setFont("Arial", "normal");
                doc.setFontSize(8);
                doc.setFont("times", "bold");
                doc.text(
                  `CH:                 ${totalCrHH || 0}              Grade Points:                  ${gp11?.toFixed(2) || 0.00}                 SGPA:                ${sgpa11?.toFixed(2) || 0.00}`
                  ,
                  7,
                  cursor1 + 2

                );


              }
              else {
                doc.text("Semester- " + roman[j - 1], 7, cursor1 + extra - 1);
                doc.setFont("Arial", "normal");
                doc.setFontSize(8);
                cursor2 = d.cursor.y;
              }

              // len = sub?.length;
              // page = doc.getNumberOfPages();
            },
          });
        } else {
          let totalCrH = crhArr[j - 1];
          let total_crh = crhArr[j - 2];
          let gp1 = this.stdCgpaInfo[j - 1].gp || 0;
          let gp2 = this.stdCgpaInfo[j - 2].gp || 0;
          let sgpa1 = this.stdCgpaInfo[j - 1].sgpa || 0;
          let sgpa2 = this.stdCgpaInfo[j - 2].sgpa || 0;

          autoTable(doc, {
            styles: {
              overflow: 'linebreak',
              fontSize: 7.5,
              cellPadding: 0,
            },
            startY: cursor1 + extra,
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 52 },
              2: { cellWidth: 10 },
              3: { cellWidth: 10 },
              4: { cellWidth: 10 },
            },
            margin: { left: 115 },
            theme: "plain",
            body: body75,
            head: header,
            tableWidth: 107,
            didDrawPage: function (d) {
              doc.setFontSize(8).setFont("Arial", "black");;
              doc.text("Semester- " + roman[j - 1], 115, cursor1 + extra - 1);
              doc.setFont("Arial", "normal");
              doc.setFontSize(8);
              cursor1 = d.cursor.y;
              cursor1 = Math.max(cursor1, cursor2);
              doc.setFont("times", "bold");
              doc.text(
                `CH:                 ${totalCrH || 0}              Grade Points:                  ${gp1?.toFixed(2) || 0.00}                 SGPA:                ${sgpa1?.toFixed(2) || 0.00}`,
                115,
                cursor1 + 2
              );
              doc.text(
                `CH:                 ${total_crh || 0}              Grade Points:                  ${gp2?.toFixed(2) || 0.00}                 SGPA:                ${sgpa2?.toFixed(2) || 0.00}`
                ,
                7,
                cursor1 + 2
              );
              doc.setFont("Arial", "normal");
              // len = sub?.length;
              // page = doc.getNumberOfPages();
              cursor2 = d.cursor.y;
            },
          });
          endRes += totalCrH + total_crh + totalCrHH;
        }
        body75 = [];
        sub = new Array<subjectInformation>();
        // cgpa = "";
        if (j == duration) {
          cursor2 = Math.max(cursor1, cursor2) + 6;
          doc.line(7, cursor2, 218, cursor2).setLineWidth(3);
          let cgpa = sgpaArr[duration - 1]?.cgpa;
          doc.setFont("times", "normal").setFontSize(8.5);

          doc.text(`Credit Hours Completed:     ${endRes}`, 7, cursor2 + 5);
          doc.text(`Grade Points Earned:       ${Math.round(totalGradePoint * 100) / 100}`, 85, cursor2 + 5); +
            doc.text(`CGPA:                     ${cgpa?.toFixed(2)} /4.00`,
              165,
              cursor2 + 5
            );

          // doc.text(
          //   "Duration of " +
          //     majTitle +
          //     " is 4 Years(8 Semester). This Result is Complete.",
          //   15,
          //   cursor2 + 10
          // );
          // let percent=Math.round(mrks/total*10000)/100;
          doc.text(`Total Marks:                         ${total}`, 7, cursor2 + 10);
          doc.text(`Obtained Marks:              ${mrks}`, 85, cursor2 + 10); +
            doc.text(`Overall Percentage:    ${this.percent}%`,
              165,
              cursor2 + 10
            );

          doc.text("Note: Errors & Omissions Excepted.", 7, cursor2 + 15);
          doc.text("Notification Date:  " + " " + dt1, 85, cursor2 + 15);
          doc.text("Date of Issuance:  " + "  " + dt, 165, cursor2 + 15);
        }
      }
      // doc.save("Transcipt " + rlNo.replace(".", "-"));
      doc.setProperties({
        title: `Transcipt- ${rlNo?.replace(".", "-")}`,

      });
      if (this.formRef.value.passYear != '' && this.formRef.value.transcript != '' && ((this.formRef.value.se_st_year >= 2020 && data.lvl == 2) || (this.formRef.value.se_st_year >= 2022 && data.lvl >= 4))) {
        const qrCodeSize = 24; // Size of the QR code in mm
        QRCode.toDataURL(this.qrCodeValue)
          .then((url: HTMLImageElement | HTMLCanvasElement | Uint8Array | RGBAData) => {
            doc.addImage(url, 'JPEG', 185, cursor2 + 28, qrCodeSize, qrCodeSize);
            window.open(URL.createObjectURL(doc.output('blob')), '_blank');
          })
          .catch((err: any) => {
            console.log("Error generating QRCode", err)
          });
      } else {
        window.open(URL.createObjectURL(doc.output('blob')), '_blank');
      }
    }
  }


  OnletterCreatePDF() {
    console.log('Letter')
    let mrks = this.mrks;
    let total = this.total;
    let sgpaArr = this.stdCgpaInfo;
    let dt1 = this.datepipe.transform(this.formRef.value.notifDate, 'dd-MM-yyyy');
    let pasYear = this.formRef.value.passYear;
    let m = 0;
    let k = 5;
    let endRes = 0;
    const roman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII'];
    // this.number1 = 'not empty';
    let data = this.stdNamesInfo;
    let crhArr = this.stdCrh.get(this.stdNamesInfo.rolnum);
    let c_Codee = this.formRef?.value?.c_code;
    if (
      data == null ||
      data == undefined
    ) {
      this.toaster.warning("No Any Data To Export");
      return;
    } else {
      this.toaster.info("Downloading");
      let extra = 0;
      let body75 = [];
      const header = [];
      let se = data.SE_ID;
      let stNm = data.NM;
      let f_nm = data.F_NM;
      let rlNo = data.ROLNO;
      let transcriptNo = this.formRef.value.transcript;
      this.qrCodeValue = `https://gcu.edu.pk/degreeVerificaton.php?transNo=${transcriptNo}`
      let rgNo = data.REG_NO;
      let majTitle = data.MAJTITLE;
      let totalGradePoint = this.total_grade_point;
      let duration = data.dur * 2;
      let durYear = data.dur;
      let dt = this.datepipe.transform(this.today, 'dd-MM-yyyy');
      let cgpaLen = this.stdCgpaInfo.length; //transcript and stdCgpaInfo size & length has same.
      // const doc = new jsPDF("p", "mm","letter");
      const doc = new jsPDF("p", "mm", [222, 279]);
      doc.addFileToVFS('FontsFree-Net-ariblk-normal.ttf', arialblack);
      doc.addFont('FontsFree-Net-ariblk-normal.ttf', 'Arial', 'black');
      doc.addFileToVFS('Arialn-normal.ttf', font);
      doc.addFont('Arialn-normal.ttf', 'Arial', 'normal');
      doc.setFont("times", "normal");
      doc.setFontSize(11);
      doc.text("" + rlNo, 157 + m, 31 + k);
      doc.text("" + rgNo, 82 + m, 31 + k);
      doc.setFont("times", "bold");
      doc.setFontSize(14);
      doc.text("" + majTitle, 112 + m, 48 + k, { align: "center" });
      doc.setFont("times", "normal");
      doc.setFontSize(10);
      doc.text("Student Name: ", 7 + m, 63.5 + k);
      doc.text("" + stNm, 40 + m, 63.5 + k);
      doc.text("Father Name: ", 115 + m, 63.5 + k);
      doc.text("" + f_nm, 145 + m, 63.5 + k);
      doc.text("Duration:", 7 + m, 68.6 + k);
      doc.text(`${durYear}-Years`, 40 + m, 68.6 + k);
      doc.text("Session: ", 115 + m, 68.6 + k);
      doc.text("" + se, 145 + m, 68.6 + k);
      doc.text("Mode of Study: ", 7 + m, 73.2 + k);
      doc.text("Regular", 40 + m, 73.2 + k);
      doc.text("Name Of Campus: ", 115 + m, 73.2 + k);
      doc.text("GC University, Lahore", 145 + m, 73.2 + k);
      doc.text("He/She has passed " + majTitle + " in the year " + pasYear + ".", 7 + m, 80.5 + k);
      let cursor1 = 86 + k;
      let cursor2 = 86 + k;
      header.push(["Course Code", "Title", "Cr H", "Grd", "GP"]);
      for (let j = 1; j <= cgpaLen; j++) {
        if (j > 2) extra = 7;
        let sub = this.transcriptMap.get(j);
        for (let i = 0; i < sub?.length; i++) {
          let arr = sub;
          body75.push([
            arr[i].SUB_CODE.toUpperCase(),
            arr[i].subject_name,
            "  " + arr[i].req_cr_hr,
            "  " + arr[i].grd,
            arr[i].GP_PER.toFixed(1)
          ]);
        }
        doc.setFontSize(9);
        let totalCrHH = 0;
        let gp11 = 0;
        let sgpa11 = 0;
        if ((c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 14)) {
          totalCrHH = crhArr[j - 1];
          gp11 = this.stdCgpaInfo[j - 1]?.gp || 0;
          sgpa11 = this.stdCgpaInfo[j - 1]?.sgpa || 0;
        }
        if (j % 2 != 0) {
          autoTable(doc, {
            styles: {
              overflow: 'linebreak',
              fontSize: 7.5,
              cellPadding: 0,
            },
            startY: cursor1 + extra,
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 52 },
              2: { cellWidth: 10 },
              3: { cellWidth: 10 },
              4: { cellWidth: 10 },
            },
            margin: { left: 7 },
            theme: "plain",
            body: body75,
            head: header,
            tableWidth: 107,
            didDrawPage: function (d) {
              doc.setFontSize(8).setFont("Arial", "black");
              if ((c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 14) && (j == 3)) {
                duration = 3;
                doc.text("Semester- " + roman[j - 1] + " & " + roman[j], 7, cursor1 + extra - 1);
                cursor1 = d.cursor.y;
                cursor1 = Math.max(cursor1, cursor2);
                doc.setFont("Arial", "normal");
                doc.setFontSize(8);
                doc.setFont("times", "bold");
                doc.text(
                  `CH:                 ${totalCrHH || 0}              Grade Points:                  ${gp11?.toFixed(2) || 0.00}                 SGPA:                ${sgpa11?.toFixed(2) || 0.00}`
                  ,
                  7,
                  cursor1 + 2

                );
              }
              else {
                doc.text("Semester- " + roman[j - 1], 7, cursor1 + extra - 1);
                doc.text("______________", 7, cursor1 + extra - 1);
                doc.setFont("Arial", "normal");
                doc.setFontSize(8);
                cursor2 = d.cursor.y;
              }

              // len = sub?.length;
              // page = doc.getNumberOfPages();
            },
          });
        } else {
          let totalCrH = crhArr[j - 1];
          let total_crh = crhArr[j - 2];
          let gp1 = this.stdCgpaInfo[j - 1].gp || 0;
          let gp2 = this.stdCgpaInfo[j - 2].gp || 0;
          let sgpa1 = this.stdCgpaInfo[j - 1].sgpa || 0;
          let sgpa2 = this.stdCgpaInfo[j - 2].sgpa || 0;
          autoTable(doc, {
            styles: {
              overflow: 'linebreak',
              fontSize: 7.5,
              cellPadding: 0,
            },
            startY: cursor1 + extra,
            columnStyles: {
              0: { cellWidth: 25 },
              1: { cellWidth: 52 },
              2: { cellWidth: 10 },
              3: { cellWidth: 10 },
              4: { cellWidth: 10 },
            },
            margin: { left: 115 },
            theme: "plain",
            body: body75,
            head: header,
            tableWidth: 107,
            didDrawPage: function (d) {
              doc.setFontSize(8).setFont("Arial", "black");;
              doc.text("Semester- " + roman[j - 1], 115, cursor1 + extra - 1);
              doc.text("______________", 115, cursor1 + extra - 1);
              doc.setFont("Arial", "normal");
              doc.setFontSize(8);
              cursor1 = d.cursor.y;
              cursor1 = Math.max(cursor1, cursor2);
              doc.setFont("times", "bold");
              doc.text(
                `CH:                 ${totalCrH || 0}              Grade Points:                  ${gp1?.toFixed(2) || 0.00}                 SGPA:                ${sgpa1?.toFixed(2) || 0.00}`,
                115,
                cursor1 + 2
              );
              doc.text(
                `CH:                 ${total_crh || 0}              Grade Points:                  ${gp2?.toFixed(2) || 0.00}                 SGPA:                ${sgpa2?.toFixed(2) || 0.00}`
                ,
                7,
                cursor1 + 2
              );
              doc.setFont("Arial", "normal");
              // len = sub?.length;
              // page = doc.getNumberOfPages();
              cursor2 = d.cursor.y;
            },
          });
          endRes += totalCrH + total_crh + totalCrHH;
        }
        body75 = [];
        sub = new Array<subjectInformation>();
        // cgpa = "";
        if (j == duration) {
          cursor2 = Math.max(cursor1, cursor2) + 6;
          doc.line(7, cursor2, 218, cursor2).setLineWidth(3);
          let cgpa = sgpaArr[duration - 1].cgpa;
          doc.setFont("times", "normal").setFontSize(8.5);

          doc.text(`Credit Hours Completed:     ${endRes}`, 7, cursor2 + 5);
          doc.text(`Grade Points Earned:       ${Math.round(totalGradePoint * 100) / 100}`, 85, cursor2 + 5); +
            doc.text(`CGPA:                     ${cgpa?.toFixed(2)} /4.00`,
              165,
              cursor2 + 5
            );

          doc.text(`Total Marks:                         ${total}`, 7, cursor2 + 10);
          doc.text(`Obtained Marks:              ${mrks}`, 85, cursor2 + 10); +
            doc.text(`Overall Percentage:    ${this.percent}%`,
              165,
              cursor2 + 10
            );

          doc.text("Note: Errors & Omissions Excepted.", 7, cursor2 + 15);
          doc.text("Notification Date:  " + " " + dt1, 85, cursor2 + 15);
          doc.text("Date of Issuance:  " + "  " + dt, 165, cursor2 + 15);
        }
      }

      doc.setProperties({
        title: `Transcipt- ${rlNo?.replace(".", "-")}`,
      });
      if (this.formRef.value.passYear != '' && this.formRef.value.transcript != '' && ((this.formRef.value.se_st_year >= 2020 && data.lvl == 2) || (this.formRef.value.se_st_year >= 2022 && data.lvl >= 4))) {
        const qrCodeSize = 24; // Size of the QR code in mm
        QRCode.toDataURL(this.qrCodeValue)
          .then((url: HTMLImageElement | HTMLCanvasElement | Uint8Array | RGBAData) => {
            doc.addImage(url, 'JPEG', 185, cursor2 + 28, qrCodeSize, qrCodeSize);
            window.open(URL.createObjectURL(doc.output('blob')), '_blank');
          })
          .catch((err: any) => {
            console.log("Error generating QRCode", err)
          });
      } else {
        window.open(URL.createObjectURL(doc.output('blob')), '_blank');
      }
    }
  }
  // New Genearte Trans
  OnMphilCreatePdf() {
    console.log('Mphil')
    let mrks = this.mrks;
    let total = this.total;
    let sgpaArr = this.stdCgpaInfo;
    let dt1 = this.datepipe.transform(this.formRef.value.notifDate, 'dd-MM-yyyy');
    let pasYear = this.formRef.value.passYear;
    let m = 0;
    let k = 17;
    let endRes = 0;
    let y = 0;
    const roman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII'];
    // this.number1 = 'not empty';
    let data = this.stdNamesInfo;
    let crhArr = this.stdCrh.get(this.stdNamesInfo.rolnum);
    let c_Codee = this.formRef?.value?.c_code;
    if (
      data == null ||
      data == undefined
    ) {
      this.toaster.warning("No Any Data To Export");
      return;
    } else {
      this.toaster.info("Downloading");
      let extra = 0;
      let body75 = [];
      const header = [];
      let se = data.SE_ID;
      let stNm = data.NM;
      let f_nm = data.F_NM;
      let rlNo = data.ROLNO;
      let transcriptNo = this.formRef.value.transcript;
      this.qrCodeValue = `https://gcu.edu.pk/degreeVerificaton.php?transNo=${transcriptNo}`;
      let rgNo = data.REG_NO;
      let majTitle = data.MAJTITLE;
      let totalGradePoint = this.total_grade_point;
      let duration = data.dur * 2;
      let durYear = data.dur;
      let dt = this.datepipe.transform(this.today, 'dd-MM-yyyy');
      let cgpaLen = this.stdCgpaInfo.length; //transcript and stdCgpaInfo size & length has same.

      // const doc = new jsPDF("p", "mm","letter");
      const doc = new jsPDF("p", "mm", [222, 310]);
      doc.addFileToVFS('FontsFree-Net-ariblk-normal.ttf', arialblack);
      doc.addFont('FontsFree-Net-ariblk-normal.ttf', 'Arial', 'black');
      doc.setFont("times");
      doc.addFileToVFS('Arialn-normal.ttf', font);
      doc.addFont('Arialn-normal.ttf', 'Arial', 'normal');
      doc.setFont("times", "normal");
      doc.setFontSize(11);
      doc.text("" + rlNo, 156 + m, 31 + k - 2);
      doc.text("" + rgNo, 81 + m, 31 + k - 2);
      doc.setFont("times", "bold");
      doc.setFontSize(14);
      doc.text("" + majTitle, 112 + m, 48 + k + 1, { align: "center" });
      doc.setFont("Times", "Roman");
      doc.setFontSize(10);
      doc.text("Student Name: ", 7 + m + 13, 63.5 + k + 6);
      doc.setFont("Times", "Roman");
      doc.setFontSize(10);
      doc.text("" + stNm, 40 + m + 13, 63.5 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Father Name: ", 115 + m + 13, 63.5 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("" + f_nm, 145 + m + 13, 63.5 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Duration:", 7 + m + 13, 68.6 + k + 6);
      doc.text(`${durYear}-Years`, 40 + m + 13, 68.6 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Session: ", 115 + m + 13, 68.6 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("" + se, 145 + m + 13, 68.6 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Mode of Study: ", 7 + m + 13, 73.2 + k + 6);
      doc.text("Regular", 40 + m + 13, 73.2 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Name Of Campus: ", 115 + m + 13, 73.2 + k + 6);
      doc.text("GC University, Lahore", 145 + m + 13, 73.2 + k + 6);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("He/She has passed " + majTitle + " in the year " + pasYear + ".", 7 + m + 13, 80.5 + k + 6);
      let cursor1 = 86 + k;
      let cursor2 = k;

      let x = 44;
      let x1 = 7.5;
      let x11 = 10;
      let x12 = 10;
      // let cursor2 = 86+k;
      header.push(["Course Code", "Title", "Cr.Hr", "Grade", "GP"]);
      for (let j = 1; j <= cgpaLen; j++) {     //<= cgpaLen
        // if (j > 2) extra = 7;
        let totalCrHH = 0;
        let gp11 = 0;
        let sgpa11 = 0
        let totalCrH = 0;
        let gp1 = 0;
        let sgpa1 = 0;
        if ((j == 3) && (c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 54 || c_Codee == 18 || c_Codee == 14)) {
          totalCrHH = crhArr[j - 1];
          gp11 = this.stdCgpaInfo[j - 1].gp || 0;
          sgpa11 = this.stdCgpaInfo[j - 1].sgpa || 0;
        }

        let sub = this.transcriptMap.get(j);

        for (let i = 0; i < sub?.length; i++) {
          let arr = sub;
          body75.push([
            arr[i].SUB_CODE.toUpperCase(),
            arr[i].subject_name,
            "    " + arr[i].req_cr_hr,
            "   " + arr[i].grd,
            arr[i].GP_PER.toFixed(1)
          ]);
        }

        doc.setFont("times", "roman");
        doc.setFontSize(10);
        autoTable(doc, {
          styles: {
            overflow: 'linebreak',
            fontSize: 9,
            cellPadding: 0.5,
            font: 'times',

          },
          startY: cursor1 + 10,
          columnStyles: {
            0: { cellWidth: 40 },
            1: { cellWidth: 70 },
            2: { cellWidth: 30 },
            3: { cellWidth: 30 },
            4: { cellWidth: 30 },
          },

          margin: { left: 20 },   //20
          theme: "plain",
          body: body75,
          head: header,
          tableWidth: 107,
          didDrawPage: function (d) {
            doc.setFont("times", "Roman");
            doc.setFontSize(10).setFont("times", "Roman");
            if ((c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 54 || c_Codee == 18 || c_Codee == 14) && (j == 3)) {
              duration = 3;
              doc.setFontSize(10);
              doc.setFont("times", "bold");
              doc.text("Semester- " + roman[j - 1] + "/" + roman[j], 20, cursor1 + 6);
              doc.text("______________", 20, cursor1 + 6.5);
              doc.setFont("times", "Roman");
              doc.setFontSize(10);
              doc.setFont("times", "bold");
              doc.setFontSize(10);
              doc.text(
                `Cr.Hr:         ${totalCrHH || 0}                                          Grade Points:                  ${gp11?.toFixed(2) || 0.00}                                        SGPA:                      ${sgpa11?.toFixed(2) || 0.00}`,
                20,
                cursor1 + 28
              );
              return;
            }
            doc.setFont("times", "bold");
            doc.setFontSize(10);
            if ((c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 54 || c_Codee == 18 || c_Codee == 14) && !(j > 3)) {
              doc.text("Semester- " + roman[j - 1], 20, cursor1 + 6);
              doc.text("__________", 20, cursor1 + 6.5);
            }
            doc.setFont("times", "Roman");
            doc.setFontSize(10);
            cursor2 += 40;
            cursor1 = (d.cursor.y) + 5;
          },
        });

        if ((j > 2) && (c_Codee == 9 || c_Codee == 10 || c_Codee == 12 || c_Codee == 17 || c_Codee == 53 || c_Codee == 77)) {   //|| c_Codee==19
          totalCrH = crhArr[j - 1];
          gp1 = this.stdCgpaInfo[j - 1].gp || 0;
          sgpa1 = this.stdCgpaInfo[j - 1].sgpa || 0;
          doc.setFont("times", "bold");
          doc.setFontSize(10);
          doc.text(
            `Cr.Hr:         ${totalCrH || 0}                                          Grade Points:                ${gp1?.toFixed(2) || 0.00}                                        SGPA:                        ${sgpa1?.toFixed(2) || 0.00}`,
            20,
            cursor1
          );
        }
        else if (j < 3) {
          totalCrH = crhArr[j - 1];
          gp1 = this.stdCgpaInfo[j - 1].gp || 0;
          sgpa1 = this.stdCgpaInfo[j - 1].sgpa || 0;

          doc.setFont("times", "bold");
          doc.setFontSize(10);
          doc.text(
            `Cr.Hr:         ${totalCrH || 0}                                          Grade Points:                ${gp1?.toFixed(2) || 0.00}                                        SGPA:                        ${sgpa1?.toFixed(2) || 0.00}`,
            20,
            cursor1
          );
        }
        x += 8;
        endRes += totalCrH + totalCrHH;

        body75 = [];
        sub = new Array<subjectInformation>();
        if ((j == duration) && (c_Codee == 9 || c_Codee == 10 || c_Codee == 12 || c_Codee == 17 || c_Codee == 53 || c_Codee == 77)) { //|| c_Codee==19
          let cgpa = sgpaArr[duration - 1]?.cgpa;
          doc.setFont("times", "Roman").setFontSize(10);
          doc.text(`Credit Hours Completed:     ${endRes}`, 20, cursor1 + x1);
          doc.text(`Grade Points Earned:       ${Math.round(totalGradePoint * 100) / 100}`, 90, cursor1 + x1); +
            doc.text(`CGPA:                     ${cgpa?.toFixed(2)} /4.00`,
              158,
              cursor1 + x1
            );
          doc.text(`Total Marks:                         ${total}`, 20, cursor1 + x1 + 5);
          doc.text(`Obtained Marks:              ${mrks}`, 90, cursor1 + x1 + 5); +
            doc.text(`Overall Percentage:    ${this.percent}%`,
              158,
              cursor1 + x1 + 5
            );
          doc.text("Note: Errors & Omissions Excepted.", 20, cursor1 + x1 + 10);
          doc.text("Notification Date:  " + " " + dt1, 90, cursor1 + x1 + 10);
          doc.text("Date of Issuance:  " + "  " + dt, 158, cursor1 + x1 + 10);
          y = 14;
        }
        else if ((j == duration) && (c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 14 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 54 || c_Codee == 18)) {
          let cgpa = sgpaArr[duration - 1]?.cgpa;
          doc.setFont("times", "Roman").setFontSize(10);
          doc.text(`Credit Hours Completed:     ${endRes}`, 20, cursor1 + x1 + 25);
          doc.text(`Grade Points Earned:       ${Math.round(totalGradePoint * 100) / 100}`, 90, cursor1 + x1 + 25); +
            doc.text(`CGPA:                     ${cgpa?.toFixed(2)} /4.00`,
              158,
              cursor1 + x1 + 25
            );
          doc.text(`Total Marks:                         ${total}`, 20, cursor1 + x1 + 30);
          doc.text(`Obtained Marks:              ${mrks}`, 90, cursor1 + x1 + 30); +
            doc.text(`Overall Percentage:    ${this.percent}%`,
              158,
              cursor1 + x1 + 30
            );
          doc.text("Note: Errors & Omissions Excepted.", 20, cursor1 + x1 + 35);
          doc.text("Notification Date:  " + " " + dt1, 90, cursor1 + x1 + 35);
          doc.text("Date of Issuance:  " + "  " + dt, 158, cursor1 + x1 + 35);
          y = 39;
        }
      }

      doc.setProperties({ title: `Transcipt- ${rlNo?.replace(".", "-")}`, });

      if (this.formRef.value.passYear != '' && this.formRef.value.transcript != '' && ((this.formRef.value.se_st_year >= 2020 && data.lvl == 2) || (this.formRef.value.se_st_year >= 2022 && data.lvl >= 4))) {
        const qrCodeSize = 24; // Size of the QR code in mm
        QRCode.toDataURL(this.qrCodeValue)
          .then((url: HTMLImageElement | HTMLCanvasElement | Uint8Array | RGBAData) => {
            doc.addImage(url, 'JPEG', 178, cursor1 + x1 + y, qrCodeSize, qrCodeSize);
            window.open(URL.createObjectURL(doc.output('blob')), '_blank');
          })
          .catch((err: any) => {
            console.log("Error generating QRCode", err)
          });
      } else {
        window.open(URL.createObjectURL(doc.output('blob')), '_blank');
      }
    }
  }

  // Testing Purpose
  OnMBACreatePdf() {
    console.log('MBA')
    let mrks = this.mrks;
    let total = this.total;
    let sgpaArr = this.stdCgpaInfo;
    let dt1 = this.datepipe.transform(this.formRef.value.notifDate, 'dd-MM-yyyy');
    let pasYear = this.formRef.value.passYear;
    let m = 0; //change this variable to change x-axis of the document
    let k = 17; //change this variable to change y-axis of the document
    let endRes = 0;
    let y = 0;
    const roman = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII'];
    // this.number1 = 'not empty';
    let data = this.stdNamesInfo;
    let crhArr = this.stdCrh.get(this.stdNamesInfo.rolnum);
    let c_Codee = this.formRef?.value?.c_code;
    if (
      data == null ||
      data == undefined
    ) {
      this.toaster.warning("No Any Data To Export");
      return;
    } else {
      this.toaster.info("Downloading");
      let extra = 0;
      let body75 = [];
      const header = [];
      let se = data.SE_ID;
      let stNm = data.NM;
      let f_nm = data.F_NM;
      let rlNo = data.ROLNO;
      let transcriptNo = this.formRef.value.transcript;
      this.qrCodeValue = `https://gcu.edu.pk/degreeVerificaton.php?transNo=${transcriptNo}`
      let rgNo = data.REG_NO;
      let majTitle = data.MAJTITLE;
      let totalGradePoint = this.total_grade_point;
      let duration = data.dur * 2;
      let durYear = data.dur;
      let dt = this.datepipe.transform(this.today, 'dd-MM-yyyy');
      let cgpaLen = this.stdCgpaInfo.length; //transcript and stdCgpaInfo size & length has same.
      // const doc = new jsPDF("p", "mm","letter");
      const doc = new jsPDF("p", "mm", [222, 310]); //279
      doc.addFileToVFS('FontsFree-Net-ariblk-normal.ttf', arialblack);
      doc.addFont('FontsFree-Net-ariblk-normal.ttf', 'Arial', 'black');
      doc.setFont("times");
      doc.addFileToVFS('Arialn-normal.ttf', font);
      doc.addFont('Arialn-normal.ttf', 'Arial', 'normal');
      doc.setFont("times", "normal");
      doc.setFontSize(11);
      doc.text("" + rlNo, 156 + m, 31 + k - 2);
      doc.text("" + rgNo, 81 + m, 31 + k - 2);
      doc.setFont("times", "bold");
      doc.setFontSize(14);
      doc.text("" + majTitle, 112 + m, 48 + k + 1, { align: "center" });
      doc.setFont("Times", "Roman");
      doc.setFontSize(10);
      doc.text("Student Name: ", 7 + m + 13, 63.5 + k + 2);
      doc.setFont("Times", "Roman");
      doc.setFontSize(10);
      doc.text("" + stNm, 40 + m + 13, 63.5 + k + 2);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Father Name: ", 115 + m + 13, 63.5 + k + 2);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("" + f_nm, 145 + m + 13, 63.5 + k + 2);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Duration:", 7 + m + 13, 68.6 + k + 2);
      doc.text(`${durYear}-Years`, 40 + m + 13, 68.6 + k + 2);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Session: ", 115 + m + 13, 68.6 + k + 2);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("" + se, 145 + m + 13, 68.6 + k + 2);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Mode of Study: ", 7 + m + 13, 73.2 + k + 2);
      doc.text("Regular", 40 + m + 13, 73.2 + k + 2);
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("Name Of Campus: ", 115 + m + 13, 73.2 + k + 2);
      doc.text("GC University, Lahore", 145 + m + 13, 73.2 + k + 2); //6
      doc.setFont("times", "Roman");
      doc.setFontSize(10);
      doc.text("He/She has passed " + majTitle + " in the year " + pasYear + ".", 7 + m + 13, 80.5 + k + 2); //6
      let cursor1 = 86 + k;
      let cursor2 = cursor1;

      let x = 44;
      let x1 = 7.5;
      let x11 = 10;
      let x12 = 10;
      // let cursor2 = 86+k;
      header.push(["Course Code", "Title", "Cr.Hr", "Grade", "GP"]);
      for (let j = 1; j <= cgpaLen; j++) {     //<= cgpaLen
        // if (j > 2) extra = 7;
        let totalCrHH = 0;
        let gp11 = 0;
        let sgpa11 = 0
        let totalCrH = 0;
        let gp1 = 0;
        let sgpa1 = 0
        if ((j == 3) && (c_Codee == 7 || c_Codee == 106 || c_Codee == 105  /*|| c_Codee == 15*/ || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 54 || c_Codee == 18 /*|| c_Codee == 14*/)) {
          totalCrHH = crhArr[j - 1];
          gp11 = this.stdCgpaInfo[j - 1].gp || 0;
          sgpa11 = this.stdCgpaInfo[j - 1].sgpa || 0;
        }
        let sub = this.transcriptMap.get(j);
        for (let i = 0; i < sub?.length; i++) {
          let arr = sub;
          body75.push([
            arr[i].SUB_CODE.toUpperCase(),
            arr[i].subject_name,
            "    " + arr[i].req_cr_hr,
            "   " + arr[i].grd,
            arr[i].GP_PER.toFixed(1)
          ]);
        }

        doc.setFont("times", "roman");

        autoTable(doc, {
          styles: {
            overflow: 'linebreak',
            fontSize: 9.5,
            cellPadding: 0,
            font: 'times',

          },
          startY: cursor1 + 5,   // cursor1+10
          columnStyles: {
            0: { cellWidth: 40 },
            1: { cellWidth: 70 },
            2: { cellWidth: 30 },
            3: { cellWidth: 30 },
            4: { cellWidth: 30 },
          },

          margin: { left: 20 },   //20
          theme: "plain",
          body: body75,
          head: header,
          tableWidth: 107,
          didDrawPage: function (d) {
            doc.setFont("times", "Roman");
            doc.setFontSize(9).setFont("times", "Roman");
            if ((c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || /*c_Codee == 15 ||*/ c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 54 || c_Codee == 18 /*|| c_Codee == 14*/) && (j == 3)) {
              duration = 3;
              doc.setFontSize(9);
              doc.setFont("times", "bold");
              doc.text("Semester- " + roman[j - 1] + "/" + roman[j], 20, cursor1 + 6);
              doc.text("______________", 20, cursor1 + 6.5);
              doc.setFont("times", "Roman");
              doc.setFontSize(9);
              doc.setFont("times", "bold");
              doc.setFontSize(8);
              doc.text(
                `Cr.Hr:         ${totalCrHH || 0}                                       Grade Points:                               ${gp11?.toFixed(2) || 0.00}                                        SGPA:                      ${sgpa11?.toFixed(2) || 0.00}`,
                20,
                cursor1 + 24
              );
              return;

            }
            doc.setFont("times", "bold");
            doc.setFontSize(8);
            doc.text("Semester- " + roman[j - 1], 20, cursor1 + 2);   //6
            doc.text("__________", 20, cursor1 + 2.5);    //6.5
            doc.setFont("times", "Roman");
            doc.setFontSize(8);
            cursor1 = (d.cursor.y) + 5;
            // for print semester
            //courses
            // len = sub?.length;
            // page = doc.getNumberOfPages();
          },
        });
        // cursor1+=30;
        // x1+=30;
        // x11+=40;

        // if(j!=3)
        if ((j > 2) && (c_Codee == 9 || c_Codee == 10 || c_Codee == 12 || c_Codee == 16 || c_Codee == 14 || c_Codee == 15 || c_Codee == 17 || c_Codee == 53 || c_Codee == 77 || c_Codee == 19 || c_Codee == 13)) {   //|| c_Codee==19
          totalCrH = crhArr[j - 1];
          // let total_crh = crhArr[j-2];
          gp1 = this.stdCgpaInfo[j - 1].gp || 0;
          // let gp2=this.stdCgpaInfo[j - 2].gp || 0;
          sgpa1 = this.stdCgpaInfo[j - 1].sgpa || 0;
          // let sgpa2=this.stdCgpaInfo[j - 2].sgpa || 0;

          doc.setFont("times", "bold");
          doc.setFontSize(8);
          // for term 3 or 4
          doc.text(
            `Cr.Hr:         ${totalCrH || 0}                                             Grade Points:                                             ${gp1?.toFixed(2) || 0.00}                                        SGPA:                        ${sgpa1?.toFixed(2) || 0.00}`,
            20,
            cursor1 - 2    //cursor1
          );

        }
        else if (j < 3) {
          totalCrH = crhArr[j - 1];
          // let total_crh = crhArr[j-2];
          gp1 = this.stdCgpaInfo[j - 1].gp || 0;
          // let gp2=this.stdCgpaInfo[j - 2].gp || 0;
          sgpa1 = this.stdCgpaInfo[j - 1].sgpa || 0;
          // let sgpa2=this.stdCgpaInfo[j - 2].sgpa || 0;

          doc.setFont("times", "bold");
          doc.setFontSize(8);
          // for term 1 or 2
          doc.text(
            `Cr.Hr:         ${totalCrH || 0}                                              Grade Points:                                            ${gp1?.toFixed(2) || 0.00}                                        SGPA:                        ${sgpa1?.toFixed(2) || 0.00}`,
            20,
            cursor1 - 2      //cursor1
          );
        }
        x += 8;
        endRes += totalCrH + totalCrHH;

        body75 = [];
        sub = new Array<subjectInformation>();
        // cgpa = "";
        if ((j == duration) && (c_Codee == 9 || c_Codee == 10 || c_Codee == 12 || c_Codee == 16 || c_Codee == 14 || c_Codee == 15 || c_Codee == 17 || c_Codee == 53 || c_Codee == 77 || c_Codee == 19 || c_Codee == 13)) { //|| c_Codee==19
          let cgpa = sgpaArr[duration - 1]?.cgpa;
          doc.setFont("times", "Roman").setFontSize(9);
          doc.text(`Credit Hours Completed:     ${endRes}`, 20, cursor1 + x1);
          doc.text(`Grade Points Earned:       ${Math.round(totalGradePoint * 100) / 100}`, 90, cursor1 + x1); +
            doc.text(`CGPA:                     ${cgpa?.toFixed(2)} /4.00`,
              158,
              cursor1 + x1
            );
          doc.text(`Total Marks:                         ${total}`, 20, cursor1 + x1 + 5);
          doc.text(`Obtained Marks:              ${mrks}`, 90, cursor1 + x1 + 5); +
            doc.text(`Overall Percentage:    ${this.percent}%`,
              158,
              cursor1 + x1 + 5
            );
          doc.text("Note: Errors & Omissions Excepted.", 20, cursor1 + x1 + 10);
          doc.text("Notification Date:  " + " " + dt1, 90, cursor1 + x1 + 10);
          doc.text("Date of Issuance:  " + "  " + dt, 158, cursor1 + x1 + 10);
          y = 14;
        }
        else if ((j == duration) && (c_Codee == 7 || c_Codee == 106 || c_Codee == 105 || c_Codee == 15 || c_Codee == 56 || c_Codee == 50 || c_Codee == 9 || c_Codee == 57 || c_Codee == 54 || c_Codee == 18 || c_Codee == 19 || c_Codee == 13 || c_Codee == 16 || c_Codee == 14)) {
          let cgpa = sgpaArr[duration - 1]?.cgpa;
          doc.setFont("times", "Roman").setFontSize(9);
          doc.text(`Credit Hours Completed:     ${endRes}`, 20, cursor1 + x1 + 25);
          doc.text(`Grade Points Earned:       ${Math.round(totalGradePoint * 100) / 100}`, 90, cursor1 + x1 + 25); +
            doc.text(`CGPA:                     ${cgpa?.toFixed(2)} /4.00`,
              158,
              cursor1 + x1 + 25
            );
          doc.text(`Total Marks:                         ${total}`, 20, cursor1 + x1 + 30);
          doc.text(`Obtained Marks:              ${mrks}`, 90, cursor1 + x1 + 30); +
            doc.text(`Overall Percentage:    ${this.percent}%`,
              158,
              cursor1 + x1 + 30
            );
          doc.text("Note: Errors & Omissions Excepted.", 20, cursor1 + x1 + 35);
          doc.text("Notification Date:  " + " " + dt1, 90, cursor1 + x1 + 35);
          doc.text("Date of Issuance:  " + "  " + dt, 158, cursor1 + x1 + 35);
          y = 39;
        }
      }
      doc.setProperties({
        title: `Transcipt- ${rlNo?.replace(".", "-")}`,
      });

      cursor2 = cursor1 + 20;

      if (this.formRef.value.passYear != '' && this.formRef.value.transcript != '' && ((this.formRef.value.se_st_year >= 2020 && data.lvl == 2) || (this.formRef.value.se_st_year >= 2022 && data.lvl >= 4))) {
        const qrCodeSize = 24; // Size of the QR code in mm
        QRCode.toDataURL(this.qrCodeValue)
          .then((url: HTMLImageElement | HTMLCanvasElement | Uint8Array | RGBAData) => {
            doc.addImage(url, 'JPEG', 178, cursor1 + x1 + y, qrCodeSize, qrCodeSize);
            window.open(URL.createObjectURL(doc.output('blob')), '_blank');
          })
          .catch((err: any) => {
            console.log("Error generating QRCode", err)
          });
      } else {
        window.open(URL.createObjectURL(doc.output('blob')), '_blank');
      }
    }
  }
}